import React, { useState, useRef } from 'react';
import { Layout, Collapse, Table, Button, Select, Row, Col } from 'antd';
import {
  HeaderLanding,
  SubHeaderLanding,
  Footer,
  SubFooter,
} from 'components/layout/components';
import { TableCell } from 'components';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { pick, mapValues } from 'lodash';
import { Excel } from 'antd-table-saveas-excel';
import { ReactComponent as RecycleHouse } from 'assets/icons/recycle.svg';
import { ReactComponent as BannerBlue } from 'assets/icons/banner.svg';
import panels from '../../assets/icons/panels.png';
import recyclehouse from '../../assets/icons/recyclehouse.png';
import bannerblue from '../../assets/icons/bannerblue.png';
import { loginUrl } from 'utils';
import FEK from '../../utils/documents/Aλλάζω_Συσκευή_για_τις_Επιχειρήσεις_Προκήρυξη_του_προγράμματος_ΦΕΚ.pdf';
import FEK_1 from '../../utils/documents/FEK-2024-Tefxos_B-04985-downloaded -04_09_2024.pdf';
import DeMinimis from '../../utils/documents/ΥΔ De Minimis (ΓΕΩΡΓΙΚΟΣ ΤΟΜΕΑΣ).docx';
import VevaiwshEktimisisCO2 from '../../utils/documents/Βεβαίωση Εκτίμησης Εκπομπών CO2.docx';
import VevaiwshMeiwshsCO2 from '../../utils/documents/Βεβαίωση Μείωσης Εκπομπών CO2.docx';
import VevaiwshEgkatastath from '../../utils/documents/Βεβαίωση Εγκαταστάτη.docx';
import OwnerConsent from '../../utils/documents/Υπόδειγμα_Υπεύθυνης_Δήλωσης_Συναίνεσης_Ιδιοκτήτη.docx'
import SorefsisConsent from '../../utils/documents/Υπόδειγμα_Υπεύθυνης_Δήλωσης_Σώρευσης.docx'
import respEnergyGoalAchieveStatement from '../../utils/documents/Υπόδειγμα_ΥΔ_Επίτευξης_Ενεργειακού_Στόχου.docx'
import FaqDocument from '../../utils/documents/FAQ_Αλλάζω_Συσκευή-v.1.pdf'
import FaqDocument2 from '../../utils/documents/FAQ Αλλάζω Συσκευή-v2.pdf'
import FaqDocument3 from '../../utils/documents/FAQ Αλλάζω Συσκευή_v3.pdf'
import FaqDocument4 from '../../utils/documents/FAQ Αλλάζω Συσκευή_v4.pdf'
import FaqDocument5 from '../../utils/documents/FAQ Αλλάζω Συσκευή_v5.pdf' 

const { Content } = Layout;
const { Panel } = Collapse;

const LandingPageRR = () => {
  const redirect = useRef(null);
  const [response, setResponse] = useState([]);
  const [elegibility, setElegibility] = useState(false);
  const [activeKey, setActiveKey] = useState();

  const documents = [
    {
      category: 'Οδηγός Προγράμματος',
      documents: [
        {
        	label: '1η Τροποποίηση Απόφασης',
          date: '25/09/2024',
        	link: FEK_1
        },
        {
        	label: 'Aλλάζω Συσκευή για τις Επιχειρήσεις Προκήρυξη του προγράμματος ΦΕΚ',
          date: '21/2/2024',
        	link: FEK
        },
      ],
    },
    {
      category: 'Διάφορα',
      documents: [
        {
          label: 'Υπεύθυνη Δήλωση De Minimis (Γεωργικός Τομέας)',
          date: '25/09/2024',
          link: DeMinimis
        },
        {
          label: 'Βεβαίωση Εκτίμησης Εκπομπών CO2',
          date: '25/09/2024',
          link: VevaiwshEktimisisCO2
        },
        {
          label: 'Βεβαίωση Μείωσης Εκπομπών CO2',
          date: '25/09/2024',
          link: VevaiwshMeiwshsCO2
        },
        {
          label: 'Βεβαίωση Εγκαταστάτη',
          date: '25/09/2024',
          link: VevaiwshEgkatastath
        },
        {
          label: 'Υπόδειγμα Υπεύθυνης Δήλωσης Σώρευσης',
          date: '04/03/2024',
          link: SorefsisConsent
        },
        {
          label: 'Υπόδειγμα Υπεύθυνης Δήλωσης Συναίνεσης Ιδιοκτήτη',
          date: '04/03/2024',
          link: OwnerConsent
        },
        {
          label: 'Υπόδειγμα ΥΔ Επίτευξης Ενεργειακού Στόχου',
          date: '19/04/2024',
          link: respEnergyGoalAchieveStatement
          
        }
      ]
    }
  ]

  const faqDocuments = [
    {
      documents: [
        {
          label: 'FAQ Αλλάζω Συσκευή_v5',
          date: '25/09/2024',
          link: FaqDocument5
        },
      ]
    }
  ]

  const scrollToSection = elementRef => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const NewsItem = ({ item }) => {
    const [showFullText, setShowFullText] = useState(false);

    return (
      <>
        <Col xs={24} sm={11} md={11} lg={7} xl={7}>
          <img src={recyclehouse} alt="recyclehouse" width="100%" />
          <div className="news-title font-size-20 mt-2">{item.title}</div>
          <div className="news-date mt-2 font-size-16">{item.date}</div>
          <div className="news-text mt-2 font-size-16">{item.subtext}</div>
          <div className="news-text mt-2 font-size-16">
            {showFullText ? item.text : ``}
          </div>
          {item.text && <div
            className="see-more mt-2 font-size-16"
            onClick={() => setShowFullText(!showFullText)}
          >
            {showFullText ? 'Απόκρυψη' : 'Δείτε Περισσότερα'}
          </div>
          }
        </Col>
        <Col span={1}></Col>
      </>
    );
  };

  const news = [
    {
      title:
        'Αλλάζω Συσκευή για τις Επιχειρήσεις: Τρεις βασικές αλλαγές στον Οδηγό του Προγράμματος',
      date: '26/09/24',
      text: <div>
        <p>Ανοικτό για υποβολή αιτήσεων μέχρι εξαντλήσεως του προϋπολογισμού</p>
        <p>Τρεις βασικές αλλαγές εισάγει στο πρόγραμμα: «Αλλάζω Συσκευή για τις Επιχειρήσεις», ύψους 105,60 εκατ. ευρώ, τροποποιητική Απόφαση (ΚΥΑ: Αριθμ. ΥΠΕΝ/ΥΔΕΝ/92784/583) του Υπουργού Περιβάλλοντος και Ενέργειας, κ. Θόδωρου Σκυλακάκη και του Αναπληρωτή Υπουργού Εθνικής Οικονομίας και Οικονομικών, κ. Νίκου Παπαθανάση.</p>
        <p><u>Ειδικότερα, η Υπουργική Απόφαση προβλέπει:</u></p>
        <p>
          &emsp;&emsp;1. <b>Απλοποίηση της δυνατότητας</b> επίτευξης του ενεργειακού στόχου που θέτει το πρόγραμμα. Συγκεκριμένα, για τις αντικαταστάσεις συσκευών (π.χ. <b>κλιματιστικό, ψυγείο, φούρνος, αντλία θερμότητας</b> κ.α.) θα αρκεί <b>μία νέα βεβαίωση</b> εκπομπών για κάθε συσκευή. Οι ενεργειακοί επιθεωρητές ή ελεγκτές θα μπορούν να την εκδίδουν (πριν και μετά την εγκατάστασή της), αντί για τη χρήση Πιστοποιητικού Ενεργειακής Απόδοσης (ΠΕΑ) ή ενεργειακού ελέγχου.
        </p>
        <p>
        &emsp;&emsp;2.Ένταξη αγροτών στους δυνητικούς δικαιούχους του προγράμματος, με δυνατότητα υποβολής αιτήσεων από σήμερα, Πέμπτη 26 Σεπτεμβρίου 2024.
        </p>
        <p>
        &emsp;&emsp;3.Ενίσχυση των ανακυκλούμενων συσκευών, ύψους <b>250 ευρώ ανά</b> συσκευή (με ανώτατο όριο τα <b>500 ευρώ</b>), επιπρόσθετη της ενίσχυσης των επιλέξιμων δαπανών
        </p>

        <p>Το πρόγραμμα παραμένει ανοικτό για υποβολή αιτήσεων από τους ενδιαφερόμενος μέχρι εξαντλήσεως του προϋπολογισμού.</p>
        <p>Στο μεταξύ, βρίσκονται σε εξέλιξη οι αξιολογήσεις των υποβληθεισών αιτήσεων. Μέσα στο επόμενο χρονικό διάστημα θα αναρτηθούν οι σχετικές αποφάσεις ένταξης των δικαιούχων. Με την απόφαση ένταξης οι δικαιούχοι θα μπορούν να ανοίξουν ειδικούς δεσμευμένους καταπιστευτικούς λογαριασμούς (escrow accounts), όπου και θα μεταφέρεται το συνολικό ποσό της ενίσχυσης.</p>
        <p>Οι αιτήσεις κατατίθενται μέσω της ηλεκτρονικής πλατφόρμας του προγράμματος:
          <a href="https://exoikonomo-epixeiro2023.gov.gr/" 
        target="_blank"
        rel="noreferrer">https://exoikonomo-epixeiro2023.gov.gr</a>
         και εφαρμόζεται η μέθοδος της άμεσης αξιολόγησης (First in-First out).</p>
         <p>Το πρόγραμμα υλοποιείται στο πλαίσιο της δράσης «<b>Εξοικονομώ – Επιχειρώντας</b>», με την υποστήριξη του Ταμείου Ανάκαμψης και Ανθεκτικότητας.</p>

        {/* Το <b>Υπουργείο Περιβάλλοντος και Ενέργειας</b> ανακοινώνει πως οι ενδιαφερόμενοι για την ένταξη στο <b>πρόγραμμα:
        «Αλλάζω συσκευή για τις επιχειρήσεις»</b>, συνολικού ύψους 105,6 εκατ. ευρώ, μπορούν να υποβάλλουν τις αιτήσεις τους 
        και μετά την 15η Μαΐου που προβλεπόταν μέχρι πρότινος.<br/>
       Για τις αιτήσεις θα εφαρμόζεται η μέθοδος της άμεσης αξιολόγησης (First in-First out).
        Συνεπώς, αυτές που θα εγκρίνονται θα δεσμεύουν και προϋπολογισμό σύμφωνα με τη χρονική σειρά υποβολής τους.
        Όσες αιτήσεις υποβληθούν μετά την εξάντληση του διαθέσιμου προϋπολογισμού θα χαρακτηρίζονται επιλαχούσες και θα ακολουθείται αντίστοιχη διαδικασία.<br/>
        Βασικός στόχος του προγράμματος είναι η ενίσχυση της αποτελεσματικότητας των σχεδιαζόμενων παρεμβάσεων εξοικονόμησης ενέργειας στις επιχειρήσεις.<br/>
        Υπενθυμίζεται πως οι αιτήσεις κατατίθενται μέσω της ηλεκτρονικής πλατφόρμας του προγράμματος:<br/>
        <a href="https://exoikonomo-epixeiro2023.gov.gr/" 
        target="_blank"
        rel="noreferrer">https://exoikonomo-epixeiro2023.gov.gr</a> */}
        
        </div>
    },
    {
      title:
        'Πρόγραμμα «Αλλάζω συσκευή για τις επιχειρήσεις»: Παραμένει ανοιχτό για υποβολή αιτήσεων',
      date: '15/05/24',
      text: <div>Το <b>Υπουργείο Περιβάλλοντος και Ενέργειας</b> ανακοινώνει πως οι ενδιαφερόμενοι για την ένταξη στο <b>πρόγραμμα:
        «Αλλάζω συσκευή για τις επιχειρήσεις»</b>, συνολικού ύψους 105,6 εκατ. ευρώ, μπορούν να υποβάλλουν τις αιτήσεις τους 
        και μετά την 15η Μαΐου που προβλεπόταν μέχρι πρότινος.<br/>
       Για τις αιτήσεις θα εφαρμόζεται η μέθοδος της άμεσης αξιολόγησης (First in-First out).
        Συνεπώς, αυτές που θα εγκρίνονται θα δεσμεύουν και προϋπολογισμό σύμφωνα με τη χρονική σειρά υποβολής τους.
        Όσες αιτήσεις υποβληθούν μετά την εξάντληση του διαθέσιμου προϋπολογισμού θα χαρακτηρίζονται επιλαχούσες και θα ακολουθείται αντίστοιχη διαδικασία.<br/>
        Βασικός στόχος του προγράμματος είναι η ενίσχυση της αποτελεσματικότητας των σχεδιαζόμενων παρεμβάσεων εξοικονόμησης ενέργειας στις επιχειρήσεις.<br/>
        Υπενθυμίζεται πως οι αιτήσεις κατατίθενται μέσω της ηλεκτρονικής πλατφόρμας του προγράμματος:<br/>
        <a href="https://exoikonomo-epixeiro2023.gov.gr/" 
        target="_blank"
        rel="noreferrer">https://exoikonomo-epixeiro2023.gov.gr</a></div>
    },
    {
      title: 'Παράταση έως τις 15/5 για την υποβολή αιτήσεων στο πρόγραμμα «Αλλάζω συσκευή για τις επιχειρήσεις»',
      date: '09/04/2024',
      text: <div>Το <b>Υπουργείο Περιβάλλοντος και Ενέργειας</b>, ανακοινώνει την παράταση του προγράμματος «Αλλάζω συσκευή για τις επιχειρήσεις», συνολικού ύψους 105,6 εκατ. ευρώ, έως τις <b>15 Μαΐου 2024</b>, στις 18:00.<br/>
      Στο πλαίσιο της σχετικής τροποποιητικής Απόφασης προβλέπεται, ότι οι δικαιούχοι θα μπορούν να ενταχθούν στο πρόγραμμα είτε υποβάλλοντας Σχετική Υπεύθυνη Δήλωση περί επίτευξης του στόχου μείωσης του CO2 κατά 30% είτε προσκομίζοντας Πιστοποιητικό Ενεργειακής Απόδοσης (ΠΕΑ)/Έκθεση Ενεργειακού Ελέγχου. Η προθεσμία είναι, επίσης, η <b>15η Μαΐου 2024</b>.<br/> 
      Σε κάθε περίπτωση, θα πρέπει να υποβληθούν τα αποδεικτικά ενεργειακής απόδοσης πριν και μετά τις παρεμβάσεις και η κατάθεσή τους δεν μπορεί να υπερβαίνει το προβλεπόμενο στον Οδηγό του προγράμματος χρονικό διάστημα υλοποίησης έργου.<br/>
      Το <b>Υπουργείο</b> προέβη σε αυτή την παράταση, αναγνωρίζοντας τον αυξημένο φόρτο εργασίας των μηχανικών, Ενεργειακών Επιθεωρητών και Ελεγκτών, που εκδίδουν τα -κατά περίπτωση- αποδεικτικά ενεργειακής απόδοσης (Πιστοποιητικό Ενεργειακής Απόδοσης/Ενεργειακός Έλεγχος).<br/>
      Υπενθυμίζεται πως οι αιτήσεις κατατίθενται μέσω της ηλεκτρονικής πλατφόρμας του προγράμματος: <a href="https://exoikonomo-epixeiro2023.gov.gr/" 
        target="_blank"
        rel="noreferrer">https://exoikonomo-epixeiro2023.gov.gr</a> και εφαρμόζεται η μέθοδος της άμεσης αξιολόγησης (First in-First out).</div>
      },
    {
      title:
        '«Αλλάζω συσκευή για τις επιχειρήσεις»: Καλύπτεται η δαπάνη Σύμβουλου Υποστήριξης Αίτησης',
      date: '29/03/24',
      text: <div>Δαπάνη Σύμβουλου Υποστήριξης Αίτησης καλύπτεται, στο πλαίσιο του προγράμματος: «Αλλάζω συσκευή για τις επιχειρήσεις», του <b>Υπουργείου Περιβάλλοντος και Ενέργειας</b>.<br/>
      Ειδικότερα, στην τροποποίηση του οδηγού προγράμματος προβλέπεται η κάλυψη δαπάνης Σύμβουλου Υποστήριξης Αίτησης για την καταχώρηση και οριστική υποβολή της αίτησης.<br/>
      Η δαπάνη των Συμβούλων ανέρχεται έως 300 ευρώ και αφορά και στις αιτήσεις που έχουν, ήδη, υποβληθεί. <br/>
      Υπενθυμίζεται πως οι ενδιαφερόμενοι μπορούν να υποβάλλουν τις αιτήσεις τους για ένταξη στο «Αλλάζω συσκευή για τις επιχειρήσεις» έως τις 15 Απριλίου 2024, μέσω της ηλεκτρονικής πλατφόρμας του προγράμματος: <a href="https://exoikonomo-epixeiro2023.gov.gr/" 
        target="_blank"
        rel="noreferrer">https://exoikonomo-epixeiro2023.gov.gr</a></div>,
      
    },
    {
      title:
        'Έως τις 15/4 η υποβολή αιτήσεων στο πρόγραμμα «Αλλάζω συσκευή για τις επιχειρήσεις',
      date: '22/03/24',
      text: <div>Ανακοίνωση (22 Μαρτίου 2024): Προθεσμία υποβολής αιτήσεων για το πρόγραμμα 
        «Αλλάζω συσκευή για τις επιχειρήσεις» έως τις 15 Απριλίου 2024 στις 18:00.<br/> Τα απαιτούμενα -κατά περίπτωση- αποδεικτικά 
        ενεργειακής απόδοσης (Πιστοποιητικό Ενεργειακής Απόδοσης/Ενεργειακός Έλεγχος) υποβάλλονται, για όλες τις αιτήσεις, 
        επίσης έως τις 15 Απριλίου 2024.<br/>
        Έως τις 15 Απριλίου 2024, στις 18:00 μπορούν να υποβάλλουν τις αιτήσεις τους οι ενδιαφερόμενοι 
        για ένταξη στο πρόγραμμα: <b>«Αλλάζω συσκευή για τις επιχειρήσεις»</b> του <b>Υπουργείου Περιβάλλοντος και Ενέργειας</b>, 
        συνολικού ύψους 105,6 εκατ. ευρώ.<br/> Αυτό προβλέπεται στην τροποποίηση του Οδηγού του προγράμματος, 
        το οποίο αρχικά ήταν ανοικτό για υποβολή αιτήσεων, μέχρι εξαντλήσεως του διαθέσιμου προϋπολογισμού.<br/> 
        Στο πλαίσιο της τροποποίησης προβλέπεται, ακόμη, πως τα απαιτούμενα -κατά περίπτωση- αποδεικτικά ενεργειακής απόδοσης 
        (Πιστοποιητικό Ενεργειακής Απόδοσης/Ενεργειακός Έλεγχος) υποβάλλονται, για όλες τις αιτήσεις, επίσης έως τις 15 Απριλίου 2024.<br/> 
        Υπενθυμίζεται πως οι αιτήσεις κατατίθενται μέσω της ηλεκτρονικής πλατφόρμας του προγράμματος: 
        <a href="https://exoikonomo-epixeiro2023.gov.gr/" 
        target="_blank"
        rel="noreferrer"> Εξοικονομώ Επιχειρώντας 
        «Αλλάζω συσκευή για τις επιχειρήσεις»</a> 
        και εφαρμόζεται η μέθοδος της άμεσης αξιολόγησης (First in-First out).</div>,
    },
  ];

  const [docsTab, setDocsTab] = useState(false);
  const [faqTab, setFaqTab] = useState(false);

  return (
    <Layout>
      <HeaderLanding className="header-landing" />
      <Content className="main-content">
        <div className="columns-container">
          <Row gutter={{ xs: 8, sm: 12, md: 24, lg: 22 }} className="mt-5 mb-3">
            <Col className="mb-5" xs={24} sm={24} md={24} lg={11} xl={9}>
              <div className="title-blue font-size-40">
                ΑΛΛΑΖΩ ΣΥΣΚΕΥΗ ΓΙΑ ΤΙΣ ΕΠΙΧΕΙΡΗΣΕΙΣ
              </div>
              <div className="mt-3 font-size-16">
              Αντικείμενο του Προγράμματος «Αλλάζω συσκευή για τις επιχειρήσεις» 
              είναι η βελτίωση της ενεργειακής απόδοσης των επιχειρήσεων και η μείωση 
              της κατανάλωσης πρωτογενούς ενέργειας και κατά συνέπεια των εκπομπών διοξειδίου άνθρακα. 
              Μέσω του Προγράμματος, παρέχεται ενίσχυση στις συμμετέχουσες επιχειρήσεις προκειμένου να 
              προβούν σε εγκατάσταση ενεργειακά αποδοτικού εξοπλισμού:
                <p />
                <ul>
                  <li>
                    Συστήματα θέρμανσης με χρήση αντλιών θερμότητας (όπου εμπεριέχονται και Ηλιοθερμικά συστήματα 
                    αύξησης της απόδοσης των αντλιών θερμότητας και μέσω αποθήκευσης θερμότητας).
                  </li>
                  <li>
                    Συστήματα ηλιακής θέρμανσης.
                  </li>
                  <li>
                    Έξυπνες συσκευές μέτρησης της καταναλισκόμενης ενέργειας (πίσω από το μετρητή του δικτύου).
                  </li>
                  <li>
                    Λοιπές παρεμβάσεις εξοικονόμησης ενέργειας, μέσω της εγκατάστασης ενεργειακά αποδοτικού 
                    εξοπλισμού και συστημάτων για την εξοικονόμηση ενέργειας στην παραγωγή, την αποθήκευση, 
                    τη διανομή προϊόντων, καθώς και τη λειτουργία των εταιρειών
                  </li>
                </ul>
                  Βασικός στόχος του Προγράμματος (του οποίου η τήρηση είναι αναγκαία) αποτελεί η μείωση 
                  εκπομπών διοξειδίου του άνθρακα (CO2) τουλάχιστον κατά 30%, είτε συνολικά της συμμετέχουσας 
                  επιχείρησης είτε σημειακά ως προς τις υλοποιημένες παρεμβάσεις. Στόχοι του Προγράμματος είναι 
                  η αύξηση της ενεργειακής απόδοσης των κτιρίων και των διαδικασιών τους σύμφωνα με τους στόχους 
                  που καθορίζονται στο ΕΣΕΚ, η εξοικονόμηση οικονομικών πόρων από τη μείωση της κατανάλωσης ενέργειας 
                  καθώς και η μείωση εκπομπών αερίων του θερμοκηπίου. Αφενός, η χρήση υψηλής ενεργειακής απόδοσης 
                  εξοπλισμού αναμένεται να μειώσει σημαντικά το μέσο ενεργειακό κόστος της ελληνικής επιχείρησης. 
                  Αφετέρου, η χαμηλότερη ζήτηση ηλεκτρικής ενέργειας συνεπάγεται και μικρότερη παραγωγή από ορυκτά 
                  καύσιμα ή μη ανανεώσιμες πηγές.
                <br></br>
                <br></br>
                <par>
                  Το πρόγραμμα Εξοικονομώ Επιχειρώντας «Αλλάζω συσκευή για τις επιχειρήσεις» υλοποιείται στο πλαίσιο του Ταμείου Ανάκαμψης – Ελλάδα 2.0.                </par>
              </div>
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              {/* <img
                className="panels-round"
                src={panels}
                alt="panels"
                style={{width:"100%", marginTop:"0", marginBottom:"-1.25rem"}}
              /> */}
            </Col>
          </Row>
        </div>
      </Content>
      <Row gutter={{ xs: 8, sm: 12, md: 24, lg: 22 }} className="mt-5 mb-5">
        <div style={{ position: 'relative', marginTop:'-13.75rem' }}>
          <img
            src={bannerblue}
            alt="bannerblue"
            width="100%"
            height="100%"
            className="content-image"
          />
          <div className="overlay"></div>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
              <div className="title-white font-size-32 text-center  mb-5">
                Θέλω να αλλάξω συσκευή
              </div>


              <Button
                id="entry-button"
                type="link"
                className="applicationButton"
              >
                <a
                  href={loginUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  Είσοδος στην Ψηφιακή Πλατφόρμα Αιτήσεων
                </a>
              </Button>
            </div>
        </div>
      </Row>
      {/* <div className="title-blue font-size-40">
        ΑΛΛΑΖΩ ΣΥΣΚΕΥΗ ΓΙΑ ΤΙΣ ΕΠΙΧΕΙΡΗΣΕΙΣ
      </div> */}
      <div className="main-content">
        <div className="title-blue font-size-32 text-center mr-5 mt-5 mb-5">
          Νέα και Ανακοινώσεις
        </div>
        <Row justify="left" gutter={{ xs: 8, sm: 12, md: 24, lg: 22 }} className="mt-5 mb-5">
          {news.map((item, index) => {
            return (
              <>
                <NewsItem key={index} item={item} />
              </>
            );
          })}
        </Row>
        <div className="collapse-header">
          <Button
            style={{backgroundColor:"#1782D5", color:"white"}}
            onClick={() => {
              setDocsTab(!docsTab);
            }}
          >
            <text>ΕΓΓΡΑΦΑ ΠΡΟΓΡΑΜΜΑΤΟΣ</text>
          </Button>
          <Button
            style={{backgroundColor:"#1782D5", color:"white"}}
            onClick={() => {
              setFaqTab(!faqTab);
            }}
          >
            <text>ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ</text>
          </Button>
        </div>
        {docsTab && (
          <div className="documents-content">
            <h1 className="documents-header">Έγγραφα Προγράμματος</h1>
            <div className="documents-items">
              {documents.map(item => (
                <div className="docs">
                  <h2 className="documents-category">{item.category}</h2>
                  <div className="documents-info">
                    {item.documents.map(item => (
                      <>
                        <a target="_blank" rel="noreferrer" href={item.link}>
                          {item.label}
                        </a>
                        <p>{item.date}</p>
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {faqTab && (
          <div className="documents-content">
            <h1 className="documents-header">Συχνές Ερωτήσεις</h1>
            <div className="documents-items">
              {faqDocuments.map(item => (
                <div className="docs">
                  {/* <h2 className="documents-category">{item.category}</h2> */}
                  <div className="documents-info">
                    {item.documents.map(item => (
                      <>
                        <a target="_blank" rel="noreferrer" href={item.link}>
                          {item.label}
                        </a>
                        <p>{item.date}</p>
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
      <Content className="main-content">
        <div style={{marginTop:"-13.5rem", marginBottom:"-2.5rem"}}>
        <Collapse
            key="1"
            className="main-content__collapse"
            expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
            expandIconPosition={'right'}
            onChange={e =>
              activeKey === '1' ? setActiveKey('') : setActiveKey('1')
            }
            activeKey={activeKey}
          >
            <Panel
              header={
                <text style={{ color: 'white' }}>
                  Επικοινωνία
                </text>
              }
              key="1"
            >
              <div>
                <b>Επικοινωνία</b>
                <br></br>
                <br></br>
                <p>
                  Για οποιαδήποτε πληροφορία σχετική με το Πρόγραμμα ή οποιοδήποτε ζήτημα που προκύπτει κατά τη διαδικασία υποβολής των αιτήσεων συμμετοχής, μπορείτε να επικοινωνήσετε μαζί μας αποστέλλοντας ένα ηλεκτρονικό μήνυμα στη διεύθυνση <a>allazo.epix@prv.ypeka.gr</a>
                </p>
                <p>
                  Για ζητήματα που προκύπτουν κατά τη διαδικασία υποβολής των αιτήσεων συμμετοχής παρακαλούμε στο μήνυμά σας να συμπεριλαμβάνετε τον Αριθμό Αίτησης ή το ΑΦΜ της επιχείρησης.
                </p>
              </div>
            </Panel>
          </Collapse>

          <Collapse
            key="2"
            className="main-content__collapse"
            expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
            expandIconPosition={'right'}
            onChange={e =>
              activeKey === '2' ? setActiveKey('') : setActiveKey('2')
            }
            activeKey={activeKey}
          >
            <Panel
              header={
                <text style={{ color: 'white' }}>
                  Δικαιούχοι του Προγράμματος
                </text>
              }
              key="2"
            >
              <div>
                <b>Δικαιούχοι του Προγράμματος</b>
                <br></br>
                <br></br>
                <p>
                Στο πλαίσιο του Προγράμματος επιδοτούνται όλες οι επιχειρήσεις, μικρές (εμπεριέχονται και οι πολύ μικρές), μεσαίες και μεγάλες επιχειρήσεις.</p>
                <p>
                Ο αριθμός απασχολουμένων και οικονομικά όρια προσδιορίζουν τις κατηγορίες επιχειρήσεων 
                </p>
                <p>
                  &emsp;&emsp;1. Η κατηγορία των πολύ μικρών, μικρών και μεσαίων επιχειρήσεων («ΜΜΕ») αποτελείται από επιχειρήσεις που απασχολούν λιγότερους από 250 εργαζομένους και των οποίων ο ετήσιος κύκλος εργασιών δεν υπερβαίνει τα 50 εκατ. ευρώ και/ή το σύνολο του ετήσιου ισολογισμού δεν υπερβαίνει τα 43 εκατ. ευρώ.
                </p>
                <p>
                  &emsp;&emsp;2. Στην κατηγορία των ΜΜΕ, ως μικρή επιχείρηση ορίζεται η επιχείρηση η οποία απασχολεί λιγότερους από 50 εργαζομένους και της οποίας ο ετήσιος κύκλος εργασιών και/ή το σύνολο του ετήσιου ισολογισμού δεν υπερβαίνει τα 10 εκατ. ευρώ.
                </p>
                <p>
                  &emsp;&emsp;3. Στην κατηγορία των ΜΜΕ, ως πολύ μικρή επιχείρηση ορίζεται η επιχείρηση η οποία απασχολεί λιγότερους από 10 εργαζομένους και της οποίας ο ετήσιος κύκλος εργασιών και/ή το σύνολο του ετήσιου ισολογισμού δεν υπερβαίνει τα 2 εκατ. Ευρώ.
                </p>
                <p>
                Σημειώνεται ότι στις επιλέξιμες δραστηριότητες (ΚΑΔ) του Προγράμματος, είναι επιλέξιμες πλέον και αυτές των Αγροτών (ΚΑΔ 01). 
                </p>
              </div>
            </Panel>
          </Collapse>

          <Collapse
            key="3"
            className="main-content__collapse"
            expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
            expandIconPosition={'right'}
            onChange={e =>
              activeKey === '3' ? setActiveKey('') : setActiveKey('3')
            }
            activeKey={activeKey}
          >
            <Panel
              header={
                <text style={{ color: 'white' }}>
                  Ύψος Ενίσχυσης
                </text>
              }
              key="3"
            >
              <div>
                <b>Ύψος Ενίσχυσης</b>
                <br></br>
                <br></br>
                <p>
                  Στο πλαίσιο του Προγράμματος επιδοτούνται όλες οι επιχειρήσεις, μικρές (εμπεριέχονται και οι πολύ μικρές), 
                  μεσαίες και μεγάλες επιχειρήσεις. Η επιδότηση βασίζεται στην ένταση της ενίσχυσης και το μέγιστο π/υ 
                  επιλέξιμων δαπανών ανά μέγεθος επιχείρησης ως ακολούθως με την επιφύλαξη μη υπέρβασης του ανώτατου ορίου 
                  σώρευσης του ΕΚ 2831/2023 de minimis (ή του ΕΚ 2013/1408 de minimis, για την περίπτωση επιχειρήσεων που 
                  δραστηριοποιούνται στην πρωτογενή παραγωγή της πρωτογενούς παραγωγής γεωργικών προϊόντων). 
                </p>
                <p>
                  Για Μικρές Επιχειρήσεις:
                </p>
                <p>
                  &emsp;&emsp;1. Μέγιστη ενίσχυση (άνευ ΦΠΑ) 10.000,00 €.
                </p>
                <p>
                  &emsp;&emsp;2. Μέγιστος προϋπολογισμός επιλέξιμων δαπανών (άνευ ΦΠΑ): 20.000,00 €.
                </p>
                <p>
                  &emsp;&emsp;3. Ένταση ενίσχυσης 50,00 %.
                </p>
                <p>
                  Για Μεσαίες ή Μεγάλες Επιχειρήσεις:
                </p>
                <p>
                  &emsp;&emsp;1. Μέγιστη ενίσχυση (άνευ ΦΠΑ) 10.000,00 €.
                </p>
                <p>
                  &emsp;&emsp;2. Μέγιστος προϋπολογισμός επιλέξιμων δαπανών (άνευ ΦΠΑ): 25.000,00 €.
                </p>
                <p>
                  &emsp;&emsp;3. Ένταση ενίσχυσης 40,00 %.
                </p>
                <p>
                  Επισημαίνεται ότι:
                </p>
                <p>
                  &emsp;&emsp;1. Το ύψος της ενίσχυσης δεν μπορεί να υπερβεί τη μέγιστη δυνατή ενίσχυση, 
                  όπως αποτυπώνεται ανωτέρω.
                </p>
                <p>
                  &emsp;&emsp;2. Οι επί μέρους δαπάνες για τις υπηρεσίες έκδοσης του κάθε ΠΕΑ ανέρχονται 
                  κατά μέγιστο στο ποσό των 400,00 €.
                </p>
                <p>
                  &emsp;&emsp;3. Οι δαπάνες που αφορούν σε υπηρεσίες ανέρχονται κατά μέγιστο στο 25,00 % 
                  των συνολικών επιλέξιμων δαπανών.
                </p>
                <p>
                  &emsp;&emsp;4. Απαιτείται να προσκομιστούν τα έγγραφα τεκμηρίωσης της ενεργειακής κατανάλωσης προ και μετά των 
                  παρεμβάσεων (ήτοι: το 1ο ΠΕΑ/Ενεργειακός έλεγχος, ή Υπεύθυνη Δήλωση περί επίτευξης του στόχου, 
                  ή Βεβαίωση εκτίμησης Μείωσης Εκπομπών CO2 πριν την έναρξη της υλοποίησης, και το 2ο ΠΕΑ/Ενεργειακός έλεγχος,
                   ή η Βεβαίωση Μείωσης Εκπομπών CO2 από Αντικατάσταση Συστήματος/Συσκευής βάσει συντελεστών απόδοσης και εκπομπών
                    κατά ΚΕΝΑΚ για τις σημειακές παρεμβάσεις μετά την υλοποίηση), ώστε να τεκμηριώνεται η επίτευξη του στόχου της 
                    μείωσης των εκπεμπόμενων ρύπων CO2 τουλάχιστον κατά 30% και να καταβληθεί η ενίσχυση.
                </p>
                <p>
                  &emsp;&emsp;5. Για την ίδια εγκατάσταση στην οποία αναφέρεται το ΠΕΑ (ή ο ενεργειακός έλεγχος ή η Βεβαίωση 
                  μείωσης Εκπομπών), δεν λαμβάνει χώρα άλλη χρηματοδότηση εξοικονόμησης ενέργειας για πρόγραμμα που αφορά στη 
                  Δράση 16874 «Εξοικονομώ επιχειρώντας».
                </p>
                <p>
                  &emsp;&emsp;6. Οι δαπάνες που ενισχύονται στο πλαίσιο του Προγράμματος δεν επιτρέπεται να 
                  ενισχυθούν από καμία άλλη πηγή, Εθνική ή Ευρωπαϊκή. 
                </p>
                <p>
                  &emsp;&emsp;7. Η μέγιστη επιλέξιμη δαπάνη του συμβούλου υποστήριξης της αίτησης είναι 300,00 € και οι εν 
                  λόγω δαπάνες έχουν ένταση ενίσχυσης 100%, επιπροσθέτως της μέγιστης ενίσχυσης των 10.000 € ανά επιχείρηση.  
                </p>
                <p>
                  &emsp;&emsp;8. Η μέγιστη δυνατή επιδότηση για την ανακύκλωση της/των παλαιάς/ών συσκευής/ών είναι 500,00 €, 
                  επιπροσθέτως της μέγιστης ενίσχυσης των 10.000 € ανά επιχείρηση και πραγματοποιείται με βάση την διαδικασία 
                  που περιγράφεται στο Κεφάλαιο 7.4 του παρόντος οδηγού.  
                </p>
              </div>
            </Panel>
          </Collapse>

          <Collapse
            key="4"
            className="main-content__collapse"
            expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
            expandIconPosition={'right'}
            onChange={e =>
              activeKey === '4' ? setActiveKey('') : setActiveKey('4')
            }
            activeKey={activeKey}
          >
            <Panel
              header={
                <text style={{ color: 'white' }}>
                  Επιλέξιμες Δαπάνες
                </text>
              }
              key="4"
            >
              <div>
                <b>Επιλέξιμες Δαπάνες</b>
                <br></br>
                <br></br>
                <p>
                  Κάθε αίτηση χρηματοδότησης αφορά στην επιδότηση:
                </p>
                <p>
                  &emsp;&emsp;1. Δαπανών Εξοπλισμού:
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;a. της δαπάνης αγοράς ενός (1) νέου συστήματος θέρμανσης με χρήση αντλιών θερμότητας,
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;b. της δαπάνης αγοράς ενός (1) νέου συστήματος ηλιακής θέρμανσης,
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;c. της δαπάνης αγοράς έξυπνών συσκευών μέτρησης της καταναλισκόμενης ενέργειας (πίσω από το μετρητή του δικτύου).
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;d. των δαπανών λοιπών παρεμβάσεων εξοικονόμησης ενέργειας για την μείωση κατανάλωσης συμβατικής ενέργειας για θέρμανση, ψύξη, κλιματισμό, φωτισμό και παραγωγή ΖΝΧ και, των δαπανών παρεμβάσεων ενεργειακά αποδοτικού εξοπλισμού που συνδέεται με την παραγωγή (ήτοι στην παραγωγή, την αποθήκευση, τη διανομή προϊόντων, καθώς και τη λειτουργία των εταιρειών).
                </p>
                <p>
                  &emsp;&emsp;2. Δαπανών Υπηρεσιών:
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;a. της δαπάνης εκτέλεσης των αναγκαίων συμπληρωματικών εργασιών για την εγκατάσταση του νέου εξοπλισμού (π.χ κόστος μεταφοράς, εγκατάστασης, παραμετροποίησης του Νέου εξοπλισμού (αντλία θερμότητας, ηλιοθερμικό σύστημα, σύστημα ηλιακής θέρμανσης, έξυπνος μετρητής κλπ.)),
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;b. της δαπάνης για την έκδοση δύο (2) Ενεργειακών Πιστοποιητικών, (Πιστοποιητικών ενεργειακής απόδοσης (ΠΕΑ)) για την εγκατάσταση υλοποίησης της επένδυσης που έχει δηλωθεί στην αίτηση. Επισημαίνεται ότι τα υποβληθέντα 2 ΠΕΑ θα πρέπει να έχουν εκδοθεί από διαφορετικούς Ενεργειακούς Επιθεωρητές. Σε κάθε περίπτωση θα πρέπει να τηρείται η νομοθεσία για το Ασυμβίβαστο των ενεργειακών επιθεωρήσεων,
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;c. της δαπάνης για την υλοποίηση Ενεργειακού Ελέγχου (πριν την έναρξη της επένδυσης και ακολούθως αυτής, αντίστοιχα της διαδικασίας για τα ΠΕΑ), η οποία είναι επιλέξιμη, μόνο στην περίπτωση όπου υπάρχουν δαπάνες της κατηγορίας δαπανών λοιπών παρεμβάσεων εξοικονόμησης ενέργειας (στην περίπτωση αυτή δεν είναι επιλέξιμη η δαπάνη ΠΕΑ). Επισημαίνεται ότι οι 2 Ενεργειακοί έλεγχοι θα πρέπει να έχουν εκδοθεί από διαφορετικούς Ενεργειακούς Ελεγκτές.
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;d. της δαπάνης για την έκδοση Βεβαιώσεων Εκτίμησης Εκπομπών CO2 υφιστάμενου Συστήματος/Συσκευής βάσει συντελεστών απόδοσης και εκπομπών κατά ΚΕΝΑΚ και Μείωσης Εκπομπών CO2 από Αντικατάσταση Συστήματος/Συσκευής βάσει συντελεστών απόδοσης και εκπομπών κατά ΚΕΝΑΚ (σε περιπτώσεις σημειακών παρεμβάσεων),
                </p>
                <p>
                  &emsp;&emsp;&emsp;&emsp;e. της δαπάνης Σύμβουλου Υποστήριξης Αίτησης για την καταχώρηση των στοιχείων της αίτησης και την επισύναψη των δικαιολογητικών και παραστατικών όπου απαιτείται από το Πληροφοριακό Σύστημα του Προγράμματος, και την ενημέρωση των αιτούντων,
                </p>
                <p>
                  Συνολικός Προϋπολογισμός Εξοπλισμού
                </p>
                <p>
                  &emsp;&emsp;1. Σύστημα θέρμανσης με χρήση αντλιών θερμότητας, όπου μπορούν αθροιστικά να κυμαίνονται από 0,00 % ως 100,00 % του συνολικού προϋπολογισμού.
                </p>
                <p>
                  &emsp;&emsp;2. Ηλιοθερμικό Σύστημα αύξησης της απόδοσης των αντλιών θερμότητας και μέσω αποθήκευσης θερμότητας όπου αθροιστικά μπορεί να κυμαίνεται από 0,00 % ως 100,00 % του συνολικού προϋπολογισμού.
                </p>
                <p>
                  &emsp;&emsp;3. Σύστημα ηλιακής θέρμανσης, όπου αθροιστικά μπορεί κυμαίνεται από 0,00 % ως 100,00 % του συνολικού προϋπολογισμού.
                </p>
                <p>
                  &emsp;&emsp;4. Έξυπνοι μετρητές (προαιρετική υποστηρικτική της κατηγορίας 1), όπου αθροιστικά μπορούν να κυμαίνονται από 0,00% ως 20,00% του συνολικού προϋπολογισμού.
                </p>
                <p>
                  &emsp;&emsp;5. Λοιπές Παρεμβάσεις Εξοικονόμησης Ενέργειας, όπου αθροιστικά μπορούν κυμαίνονται από 0,00 % ως 100,00 % του συνολικού προϋπολογισμού (απαιτεί και την υλοποίηση ενεργειακού Ελέγχου, εκτός από όταν οι παρεμβάσεις αυτές συμπεριλαμβάνονται από τον τρόπο υπολογισμού του ΠΕΑ).
                </p>
                <p>
                  Συνολικός Προϋπολογισμός Υπηρεσιών
                </p>
                <p>
                  &emsp;&emsp;1. Συμπληρωματικές εργασίες για την εγκατάσταση του νέου εξοπλισμού, όπου αθροιστικά μπορούν να κυμαίνονται από 0,00 % ως 25,00 % του συνολικού προϋπολογισμού.
                </p>
                <p>
                  &emsp;&emsp;2. Έκδοση δύο (2) Ενεργειακών Πιστοποιητικών, (Πιστοποιητικών ενεργειακής απόδοσης (ΠΕΑ)) ή και δαπάνες έκδοσης της Βεβαίωσης Εκτίμησης Εκπομπών CO2 υφιστάμενου Συστήματος/Συσκευής βάσει συντελεστών απόδοσης και εκπομπών κατά ΚΕΝΑΚ και της Βεβαίωσης Μείωσης Εκπομπών CO2 από Αντικατάσταση Συστήματος/Συσκευής βάσει συντελεστών απόδοσης και εκπομπών κατά ΚΕΝΑΚ. Μπορούν να κυμαίνονται έως 400€ ανά ΠΕΑ ή έως 400€ για το σύνολο των βεβαιώσεων ανά στάδιο πριν/μετά (στην περίπτωση αυτή δεν γίνονται επιλέξιμες οι υπηρεσίες διενέργειας ενεργειακών ελέγχων).
                </p>
                <p>
                  &emsp;&emsp;3. Διενέργεια δύο (2) Ενεργειακών Ελέγχων. Μπορούν να κυμαίνονται έως 500 € ανά ενεργειακό έλεγχο στο πλαίσιο των δαπανών Υπηρεσιών (στην περίπτωση αυτή δεν γίνονται επιλέξιμες οι υπηρεσίες διενέργειας ΠΕΑ).
                </p>
                <p>
                  &emsp;&emsp;4. Υπηρεσίες Σύμβουλου Υποστήριξης Αίτησης. Μπορούν να κυμαίνονται έως 300,00 € (επιπροσθέτως της μέγιστης ενίσχυσης στο πρόγραμμα).
                </p>
                <p>
                Επιδότηση Ανακύκλωσης Συσκευών: Στην περίπτωση που ο Ωφελούμενος πραγματοποιεί ανακύκλωση της/των παλαιάς/ών συσκευής/ών, δύναται να επιδοτηθεί επιπροσθέτως της μέγιστης ενίσχυσης των 10.000€ ανά επιχείρηση έως και 500,00€. Ειδικότερα, θα δίνεται ενίσχυση 250€ ανά συσκευή με ανώτατο όριο τα 500€, δηλαδή μέχρι δύο (2) συσκευές (ήτοι 250€/ανά συσκευή.)
                </p>
              </div>
            </Panel>
          </Collapse>

          <Collapse
            key="5"
            className="main-content__collapse"
            expandIcon={({ isActive }) => (isActive ? <Minus /> : <Plus />)}
            expandIconPosition={'right'}
            onChange={e =>
              activeKey === '5' ? setActiveKey('') : setActiveKey('5')
            }
            activeKey={activeKey}
          >
            <Panel
              header={
                <text style={{ color: 'white' }}>
                  Διαδικασία Υποβολής Αίτησης Συμμετοχής
                </text>
              }
              key="5"
            >
              <div>
                <b>Διαδικασία Υποβολής Αίτησης Συμμετοχής</b>
                <br></br>
                <br></br>
                <p>
                  Ο χρήστης για να ολοκληρώσει την υποβολή της Αίτησης Συμμετοχής θα πρέπει να
                  συμπληρώσει στην πλατφόρμα τα ακόλουθα στοιχεία:
                </p>
                <p>
                  &emsp;&emsp;1. Τα βασικά στοιχεία επιχείρησης.
                </p>
                <p>
                  &emsp;&emsp;2. Τα στοιχεία των επιχειρήσεων με τις οποίες συνιστά ενιαία επιχείρηση κατά την
                  έννοια του άρθρ. 2 παρ.2 του Κανονισμού ΕΕ 2023/2831.
                </p>
                <p>
                  &emsp;&emsp;3. Το σύνολο των ενισχύσεων ήσσονος σημασίας που έχει λάβει αυτός και οι
                  επιχειρήσεις που συνιστούν ενιαία με αυτόν επιχείρηση κατά τη διάρκεια των τριών
                  τελευταίων ετών.
                </p>
                <p>
                  &emsp;&emsp;4. Τα στοιχεία ταυτότητας εκπροσώπων.
                </p>
                <p>
                  &emsp;&emsp;5. Τα στοιχεία εταιρικής σύνθεσης.
                </p>
                <p>
                  &emsp;&emsp;6. Τα στοιχεία του τόπου εκτέλεσης υλοποίησης των δαπανών.
                </p>
                <p>
                  &emsp;&emsp;7. Τις επιλέξιμες δαπάνες που εκτιμά ότι θα υλοποιήσει.
                </p>
                <p>
                  &emsp;&emsp;8. Τους όρους και τις προϋποθέσεις.
                </p>
                <p>
                  Ο χρήστης υποβάλει τα παραπάνω στοιχεία μέσω συμπλήρωσης των αντίστοιχων φορμών ή/και προσκόμισης των απαιτούμενων δικαιολογητικών.
                </p>
                <p>
                  Να σημειωθεί ότι κατά τη διάρκεια υποβολής της αίτησης συμμετοχής διενεργείται
                  αυτόματα από την πλατφόρμα, μέσω του Πληροφοριακού Συστήματος Σώρευσης Κρατικών
                  Ενισχύσεων (Sorefsis.gr), έλεγχος σώρευσης έτσι ώστε εντοπιστούν τυχόν κρατικές
                  ενισχύσεις που έχει λάβει η επιχείρηση.
                </p>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Content>
      <Content>
        <Footer />
      </Content>
    </Layout>
  );
};

export default LandingPageRR;
