import React from 'react';
import { Layout } from 'antd';

import { ReactComponent as EllinikiDimokratia } from 'assets/icons/logo.gov.svg';
import { ReactComponent as Ellada20Revert } from 'assets/icons/ellada_2_0_revert.svg';
// import { loginUrl } from 'utils';

const { Header } = Layout;

const LayoutHeader = route => {
  return (
    <Header className="app-layout__header_landing">
      <div className="header-left">
        <EllinikiDimokratia className="app-layout__logo-gr-democracy" 
          onClick={() => (window.open('https://www.gov.gr/', '_blank'))}
        />
      </div>
      <div className="header-right">
        <div>
          <Ellada20Revert
              className="app-layout__logo-gr-2-0"
              onClick={() => (window.open('https://greece20.gov.gr/', '_blank'))}
          />
        </div>   
      </div>
    </Header>
  );
};

export default LayoutHeader;
