import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { Header, Footer, SubFooter } from './components';
const { Content } = Layout;

const AppLayout = () => {
  return (
    <div>
      <Layout className="app-layout">
        <Header />
        <Content className="app-layout__content">
          <Outlet />
        </Content>
        <SubFooter/>
        <Footer/>
      </Layout>
    </div>
  );
};

export default AppLayout;
