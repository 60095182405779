import React, { useState, useEffect } from 'react';
import { Layout, Tooltip } from 'antd';
import { CloseOutlined, MenuOutlined, DownOutlined } from '@ant-design/icons';

import { ReactComponent as EllinikiDimokratia } from 'assets/icons/logo.gov.svg';
// import { ReactComponent as Ellada20Revert } from 'assets/icons/ellada_2_0_revert.svg';
import { ReactComponent as Ellada20Normal} from 'assets/icons/ellada_2_0_normal.svg'; 
import { ReactComponent as Operator } from 'assets/icons/operator_icon.svg';
import { ReactComponent as LogoMinistryEnvEnergy } from 'assets/icons/ministry_env_and_energy.gov.svg';
// import { loginUrl } from 'utils';

const { Header } = Layout;

const HeaderLanding = () => {
  const[ showDropdown, setShowDropdown]=useState(false);
  const [tooltipText, setTooltipText] = useState("");

  useEffect(() => {
    // Define a breakpoint width for changing the Tooltip overlay
    const breakpoint = 700; // Adjust to your specific breakpoint

    const handleResize = () => {
      // Check the current window width
      const currentWidth = window.innerWidth;

      // Set the Tooltip overlay based on the width
      if (currentWidth < breakpoint) {
        setTooltipText(<span>
          <b>Επικοινωνία</b><br/>
          <div style={{marginTop: "25px"}}>Για πληροφορίες σχετικά με το<br/> πρόγραμμα μπορείτε να επικοινωνείτε 
          με <br/> τo γραφείο εξυπηρέτησης (Help Desk)<br/>
          {/* 2107000801<br/> */}
          Ώρες εξυπηρέτησης κοινού: 09:00 – 17:00,<br/> Δευτέρα ως Παρασκευή
          εκτός αργιών<br/> 
          Για εξειδικευμένα ερωτήματα,<br/> διευκρινήσεις ή επίλυση ζητημάτων που<br/> 
          αφορούν συγκεκριμένες αιτήσεις ή<br/> συγκεκριμένες συναλλαγές, 
          παρακαλούμε<br/> υποβάλετε το αίτημα ηλεκτρονικά<br/> συμπληρώνοντας την
          παρακάτω φόρμα.</div>
        </span> );
      } else {
        setTooltipText(<span>
          <b>Επικοινωνία</b><br/>
          <div style={{marginTop: "25px"}}>Για πληροφορίες σχετικά με το πρόγραμμα μπορείτε να επικοινωνείτε<br/> 
          με τo γραφείο εξυπηρέτησης (Help Desk)<br/> 
          {/* 2107000801<br/> */}
          Ώρες εξυπηρέτησης κοινού: 09:00 – 17:00, Δευτέρα ως Παρασκευή<br/>
          εκτός αργιών<br/> 
          Για εξειδικευμένα ερωτήματα, διευκρινήσεις ή επίλυση ζητημάτων που<br/> 
          αφορούν συγκεκριμένες αιτήσεις ή συγκεκριμένες συναλλαγές,<br/> 
          παρακαλούμε υποβάλετε το αίτημα ηλεκτρονικά συμπληρώνοντας την<br/>
          παρακάτω φόρμα.</div>
        </span> );
      }
    };

    // Initialize the Tooltip overlay based on the initial window width
    handleResize();

    // Add a resize event listener to update the Tooltip overlay
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Header className="app-layout__header_landing">
      <div className="header-left header-padding">
        <EllinikiDimokratia id='gov' className="app-layout__logo-gr-democracy" style={{marginLeft: '2rem'}}
          onClick={() => (window.open('https://www.gov.gr/', '_blank'))}
        />
        {/* <DigitalTransformation className="app-layout__logo-gr-digital-transformation"/> */}
      </div>
      <div className="header-right">
        <div>
          </div>
          <div className='header-links'>
            <Ellada20Normal/>
          </div>
        </div>
        <div className='header-icon-mobile'>
            {!showDropdown && <MenuOutlined onClick={()=> setShowDropdown(true)}/>}
            {showDropdown && <CloseOutlined onClick={()=> setShowDropdown(false)}/>}
        </div>
      
    </Header>
  );
};

export default HeaderLanding;
