import React from 'react';
import AlertPage from 'components/alert';
import warning from 'assets/icons/warning_blue.gov.svg';
import { Header, Footer } from 'components/layout/components';

const MaintenancePage = () => {
  return (
    <>
    <Header/>
     <AlertPage
      type={'warning'}
      src={warning}
      message={'Η πλατφόρμα είναι υπό Συντήρηση'}
      submessage={'Παρακαλούμε δοκιμάστε αργότερα'}
    />
    <Footer/>
    </>
  );
};

export default MaintenancePage;
