import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from '../components';
import { routes } from './config';
import { MaintenancePage, LandingPageRR} from 'views';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route element={<LandingPage />} path={'/landing'} /> */}
        <Route element={<LandingPageRR />} path={'/'} />
        <Route element={<MaintenancePage />} path={'/maintenance'} />
        <Route element={<Layout />}>
          {routes.map(({ path, requireAuth, Element }, index) => (
            <Route key={index} path={path} element={<Element />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
