import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const SubFooter = () => {
    return (
        <Content className='subFooter'>
                  <div className='title-blue font-size-24 contentPadding mt-2'>
                      Επικοινωνία
                  </div>
                  <div className='content font-size-20'>
                  Για πληροφορίες σχετικά με το πρόγραμμα μπορείτε να επικοινωνείτε με τo γραφείο εξυπηρέτησης (Help Desk) στον αριθμό τηλεφώνου: 2107000801 <br></br>
                    <div className='mt-2'>Ώρες εξυπηρέτησης κοινού: 09:00 – 17:00, Δευτέρα ως Παρασκευή εκτός αργιών</div> <br></br>
                    Για εξειδικευμένα ερωτήματα, διευκρινήσεις ή επίλυση ζητημάτων που αφορούν συγκεκριμένες αιτήσεις ή συγκεκριμένες συναλλαγές, παρακαλούμε υποβάλετε το αίτημα ηλεκτρονικά συμπληρώνοντας την παρακάτω φόρμα.
                      
                </div>
        </Content>
    );
  };
  
  export default SubFooter;