import React from 'react';
import { Alert, Row, Col } from 'antd';

const AlertPage = ({ type, src, message, submessage, action }) => {
  return (
    <Row>
      <Col span={24}>
        <Alert
          type={type}
          description={
            <div className="ant-alert-description-message">
              <img alt={type} src={src} />
              <p>{message}</p>
              {submessage ? <span>{submessage}</span> : ''}
            </div>
          }
        ></Alert>
      </Col>
    </Row>
  );
};

export default AlertPage;
