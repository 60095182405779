import React from 'react';

const eligibilityError = () => {
  return (
    <div>
      <div className="content-title color-black w-75">
        Λυπούμαστε αλλά δεν πληροίτε τις προϋποθέσεις συμμετοχής του Προγράμματος.
      </div>
      <div className="content-paragraph color-black w-75">
        Το Πρόγραμμα «ΑΝΑΠΤΥΞΗ ΨΗΦΙΑΚΩΝ ΠΡΟΪΟΝΤΩΝ ΚΑΙ ΥΠΗΡΕΣΙΩΝ» απευθύνεται σε Μικρές Και Μεσαίες Επιχειρήσεις 
        Μικρές Και Μεσαίες Επιχειρήσεις που απασχολούν λιγότερους από 250 εργαζομένους (ισοδύναμα πλήρους 
        απασχόλησης) και των οποίων ο ετήσιος κύκλος εργασιών δεν υπερβαίνει τα 50 εκατ. ευρώ και/ή το σύνολο 
        του ετήσιου ισολογισμού δεν υπερβαίνει τα 43 εκατ. ευρώ.
      </div>
      <div className="content-paragraph color-black w-75">
        Το Πρόγραμμα ενισχύει επενδυτικά σχέδια που στοχεύουν στον σχεδιασμό, την ανάπτυξη και την εμπορική 
        αξιοποίηση νέων, καινοτόμων, ψηφιακών προϊόντων και υπηρεσιών που βασίζονται σε σύγχρονες τεχνολογίες 
        με στόχο, μεταξύ άλλων, τη λειτουργία νεοφυπολογιστικών υποδομών και υπηρεσιών για τις μικρομεσαίες 
        επιχειρήσεις, την υποστήριξη των μικρομεσαίων επιχειρήσεων για την αξιοποίηση υποδομών και υπηρεσιών Cloud, 
        την ανάπτυξη διαδικτυακών υπηρεσιών λογισμικού. Απώτερος στόχος του Προγράμματος είναι η αύξηση της 
        ανταγωνιστικότητας του κλάδου ΤΠΕ, μέσω της προσφοράς σύγχρονων, ποιοτικά αναβαθμισμένων προϊόντων, 
        που δύναται να σταθούν επάξια τόσο στην εγχώρια όσο και στη διεθνή αγορά.
      </div>  
    </div>
  );
};

export default eligibilityError;
