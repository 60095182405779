import { GdprPage } from '../views';
import { EligibilityError } from '../views';

export const routes = [
  {
    name: 'gdpr-page',
    path: '/static/gdprTerms',
    Element: GdprPage,
    requireAuth: false,
  },
  {
    name: 'eligibilityError-page',
    path: '/eligibilityError',
    Element: EligibilityError,
    requireAuth: false,
  },
];
